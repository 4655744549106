import styled from "styled-components";

const Title = styled.p`
  color: var(--primary-text);
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 1.6;
  display: inline;
`;

export default Title;
