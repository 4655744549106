import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import * as s from "./styles/globalStyles";

class Codex extends Component {
  render() {
    return (
		<Container style={{
				color: "var(--primary-text)",
				fontSize: "32px",
			  }}>
		  <Row style={{
				color: "var(--primary-text)",
				fontSize: "48px",
			  }}>Codex</Row>
        <s.SpacerSmall />
        <s.SpacerSmall />

        <Row>Atlantica is a collection of 10,000 unique CAPSULE NFTs - a digital version of the gachapon capsule toys popular in Japan.</Row>
        <s.SpacerSmall />
        
        <Row>With over 100 species, 60 variants, and endless combinations of traits, each CAPSULE NFT contains a verifiably rare and unique gachapon artwork and is stored as an ERC-721 token on the Ethereum blockchain.</Row>
          <s.SpacerSmall />

        <Row>Purchasing a capsule will cost 0.08 ETH each and will be limited per address.</Row>
          <s.SpacerSmall />
          
        <Row>Owning a capsule will grant you access to all future events in the expansion of the CAPSULEVerse. Stay tuned...</Row>
      </Container>
    )
  }
}
 
export default Codex;