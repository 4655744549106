import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const StyledImg = styled.img`
  border-radius: 10%;
  width: 150px;
  transition: width 0.5s;
  z-index: -9999;
`;

const Header = styled.p`
  color: var(--primary-text);
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 1.6;
  display: inline;
`;

const Text = styled.p`
  color: var(--primary-text);
  text-align: left;
  display: inline;
`;

class ShellText extends Component {
  render() {
    return (
      <Text style={{ color: "var(--secondary-text)" }}>
        $SHELL
      </Text>
    )
  }
}

class Shell extends Component {
  render() {
    return (
      <Container style={{ color: "var(--primary-text)", fontSize: "16px", textAlign: "center", fontWeight: "500", paddingTop: "100px", zIndex: "1" }}>
        <s.SpacerSmall />
        <Container>
          <Header>
            Shell
          </Header>
        </Container>
        <s.SpacerSmall />
        <Container style={{ textAlign: "left" }}>
          <Row>
            <Container style={{ fontWeight: "bold", color: "#7ef5e1" }}>
              Where Shell holds priceless value.
            </Container>
            <Text>
              <ShellText/> is Atlantica's very own currency. As this underwater world expands more utility
              will be added. There are more of secrets and mysteries waiting for you to discover
              which <ShellText/> serves as the life source of very Atlantean and the main resource that
              let us fuel our ecosystem, on the blockchain
            </Text>
          </Row>
          <Row>
            <Container className="MobileNewLine" style={{ display: "flex", alignItems: "center", paddingLeft: "15%", paddingRight: "15%", paddingTop: "32px" }}>
              <StyledImg alt={"Atlantica"} src={"/config/images/shell/shell_gold.webp"}/>
              <Container className="MobileShellTextMargin" >
                <Container className="MobileCenterNoMarginText" style={{ fontWeight: "bold", padding: "0px", color: "#7ef5e1" }}>
                  SEARCH FOR SINKED TREASURE
                </Container>
                <Text>
                  Every Atlantean will yields <ShellText/> everyday. As they will automatically
                  dive and search for the sinked treasure around Atlantica.
                </Text>
              </Container>
            </Container>
          </Row>
          <Row>
            <Container className="MobileNewLine" style={{ display: "flex", alignItems: "center", paddingLeft: "15%", paddingRight: "15%", paddingTop: "32px" }}>
              <StyledImg alt={"Atlantica"} src={"/config/images/shell/scroll_red.webp"}/>
              <Container className="MobileShellTextMargin" >
                <Container className="MobileCenterNoMarginText" style={{ fontWeight: "bold", padding: "0px", color: "#7ef5e1" }}>
                  CREATE SPECTACULAR LEGENDS
                </Container>
                <Text>
                  Every Atlantean have their own background and lore to be added
                  some <ShellText/> will be the price to sculpt it forever in the blockchain
                  Got a cool name? well thats's just another amount of <ShellText/> too
                </Text>
              </Container>
            </Container>
          </Row>
          <Row>
            <Container className="MobileNewLine" style={{ display: "flex", alignItems: "center", paddingLeft: "15%", paddingRight: "15%", paddingTop: "32px" }}>
              <StyledImg alt={"Atlantica"} src={"/config/images/shell/double_dolphin.webp"}/>
              <Container className="MobileShellTextMargin" >
                <Container className="MobileCenterNoMarginText" style={{ fontWeight: "bold", padding: "0px", color: "#7ef5e1" }}>
                  DECORATE YOUR OWN AQUARIUM
                </Container>
                <Text>
                  Gathering our collection store where you can use <ShellText/> to buy
                  new items for decorate your own aquarium; accessories, background, etc.
                </Text>
              </Container>
            </Container>
          </Row>
          <Row>
            <Container className="MobileNewLine" style={{ display: "flex", alignItems: "center", paddingLeft: "15%", paddingRight: "15%", paddingTop: "32px" }}>
              <StyledImg alt={"Atlantica"} src={"/config/images/shell/shells.webp"}/>
              <Container className="MobileShellTextMargin" >
                <Container className="MobileCenterNoMarginText" style={{ fontWeight: "bold", padding: "0px", color: "#7ef5e1" }}>
                  THE NEXT GENERATION OF ATLANTEAN
                </Container>
                <Text>
                  With two Atlantean and some <ShellText/>, they will cast a spell to summon you
                  the next generation of Atlantean who will help you rebuild and protect the
                  kingdom of Atlantica.
                </Text>
              </Container>
            </Container>
          </Row>
          <Row style={{ paddingTop: "64px", textAlign: "center" }}>
            <Container style={{ backgroundColor: "rgba(0, 0, 0, 0.8)", borderRadius: "20px" }}>
              <s.SpacerMedium/>
              <Container style={{ fontWeight: "bold", padding: "0px", color: "var(--secondary-text)" }}>
                $SHELL Secret shop is ON THE WAY!
              </Container>
              <s.SpacerMedium/>
              <Container style={{ paddingLeft: "15%", paddingRight: "15%" }}>
                <Container>
                  The Secret Shop will be the place to equip your VX, and spend <ShellText/>
                </Container>
                <Container>
                  Holding an Atlantean will allow for some hidden advantages.
                </Container>
              </Container>
              <s.SpacerMedium/>
              <s.SpacerMedium/>
            </Container>
          </Row>
        </Container>
      </Container>
    )
  }
}
 
export default Shell;
