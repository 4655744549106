// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
// log
import { fetchData } from "../data/dataActions";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        let networkId = await ethereum.request({
          method: "net_version",
        });

        console.log(accounts)
        if (networkId != CONFIG.NETWORK.ID) {
          let chainId = CONFIG.NETWORK.ID // ETH = 1
          let hexString = chainId.toString(16);
          let chain = '0x' + hexString
          console.log(chain)
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chain }],
          });
        }
        networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == CONFIG.NETWORK.ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            console.log("new account")
            console.log(accounts[0])
            dispatch(updateAccount(accounts[0]));
            // TODO: check if new account not connect, which account use to mint. If not expected should we disconnect instead?
          });
          ethereum.on("chainChanged", () => {
            // console.log(chainChanged)
            // TODO: Dispatch disconnect wallet or set account to zero?
            // TODO: disconnect button set account to none?
            // request network, if network != config => window.location.reload();
          });
          // Add listeners end
        } else {
          // dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
          // TODO: we should be able to prompt to switch again
        }
      } catch (err) {
		  // Show error
        console.error(err)
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Please Install Metamask."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
