import { Col, Row } from "react-bootstrap";
import styles from './Phase.module.scss';

const Phase = ({ title, description, offset }) => {
  return (
    <>
      <Col md={0} lg={offset}/>
      <Col className={styles.container}>
        <Row className={styles.title}>
          <span>{title}</span>
        </Row>
        <Row className={styles.text}>
          <span>{description}</span>
        </Row>
      </Col>
    </>
  );
}

export default Phase;
