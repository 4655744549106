import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import styled from "styled-components";

import TeamItem from './components/TeamItem';
import * as s from "./styles/globalStyles";

const Header = styled.p`
  color: var(--primary-text);
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 1.6;
  display: inline;
`;

const StyledImg = styled.img`
  border: 10px var(--primary);
  border-radius: 10%;
  width: 140px;
  transition: width 0.5s;
  z-index: -9999;
  display: flex;
`;

const Text = styled.p`
  color: var(--primary-text);
  text-align: left;
  display: inline;
`;

const PositionInfo = {
  teamLead: {
    title: "Team Lead",
    description: "UX Designer by day and a Marketer by night. Strong experience in UX & Marketing role in tech-startup",
  },
  artDirector: {
    title: "Art Director",
    description: "Painting is easy when you don’t know how, but very difficult when you do",
  }
}

class Team extends Component {
  render() {
    return (
      <Container style={{ color: "var(--primary-text)", fontSize: "16px", textAlign: "center", paddingLeft: "5%", paddingRight: "5%", paddingTop: "100px" }}>
        <s.SpacerSmall />
        <Container>
          <Header>
            The Council of Atlantica
          </Header>
        </Container>
        <s.SpacerSmall />
        <Container>
          We are the alliance of geeky surface people who specialize in wide range of skills
        </Container>
        <Container>
          and want to create a paradise for those who also have passion in art & technology.
        </Container>
        <s.SpacerSmall />
        <s.SpacerSmall />
        <Container>
          To fulfill you both aesthetically and profitability. We hope you see Atlantica as a strong community
        </Container>
        <Container>
          where people come to exchange and input more ideas to add more value to our kingdom.
        </Container>
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.SpacerSmall />
        <s.SpacerSmall />
        <Container style={{ textAlign: "left" }}>
          <Row className="NoMarginMobile PaddingDesktop" style={{ margin: "32px", marginTop: "0px" }} >
            <Col>
              <TeamItem
                title="Mutex"
                subTitle={PositionInfo.teamLead.title}
                description={PositionInfo.teamLead.description}
                imgUrl="/config/images/team/mutex_icon.webp"
              />
            </Col>
            <Col>
              <TeamItem
                title="SugarrBunny"
                subTitle={PositionInfo.artDirector.title}
                description={PositionInfo.artDirector.description}
                imgUrl="/config/images/team/sugarr_bunny_icon.webp"
              />
            </Col>
          </Row>
          <Row className="NoMarginMobile PaddingDesktop" style={{ margin: "32px", marginTop: "0px" }} >
            <Col>
              <TeamItem
                title="Bally"
                subTitle={PositionInfo.teamLead.title}
                description={PositionInfo.teamLead.description}
                imgUrl="/config/images/team/bally_icon.webp"
              />
            </Col>
            <Col>
              <TeamItem
                title="Porieploy"
                subTitle={PositionInfo.artDirector.title}
                description={PositionInfo.artDirector.description}
                imgUrl="/config/images/team/porie_ploy_icon.webp"
              />
            </Col>
          </Row>
          <Row className="NoMarginMobile PaddingDesktop" style={{ margin: "32px", marginTop: "0px" }} >
            <Col>
              <TeamItem
                title="PANDAMONIUM"
                subTitle={PositionInfo.teamLead.title}
                description={PositionInfo.artDirector.description}
                imgUrl="/config/images/team/panda_icon.webp"
              />
            </Col>
            <Col>
              <TeamItem
                title="MUAI"
                subTitle={PositionInfo.artDirector.title}
                description={PositionInfo.artDirector.description}
                imgUrl="/config/images/team/muai_icon.webp"
              />
            </Col>
          </Row>
        </Container>
        <Container style={{ paddingBottom: "15%", zIndex: -1 }} />
      </Container>
    )
  }
}
 
export default Team;