import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const Header = styled.p`
  color: var(--primary-text);
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 1.6;
  display: inline;
`;

const Text = styled.p`
  color: var(--primary-text);
  text-align: center;
  display: inline;
`;

class RoadmapHeader extends Component {
  render() {
    return (
    <Container style={{ color: "var(--primary-text)", fontSize: "16px", textAlign: "center", fontWeight: "500", paddingTop: "100px" }}>
      <s.SpacerSmall />
      <Container>
        <Header>
          How it all began
        </Header>
      </Container>
      <s.SpacerSmall />
      <s.SpacerSmall />
      <Container>
        <Container>
          <Text>
            Beneath the sea... hiding among sea creatures stand the kingdom of Atlantica
          </Text>
        <Container>
          <Text>
            where the Atlantean live in harmony with duty to protect and preserve nature balance as it should be.
          </Text>
        </Container>
        <s.SpacerSmall />
        <Container>
          <Text>
            Incautious by their extraordinary power, one day Atlantica was suddenly attacked by an unknown fearful force
          </Text>
        </Container>
        <Container>
          <Text>
            that make all of the Atlantean trapped inside the bubble seal for endless time and leave their glory kingdom into a ruin.
          </Text>
        </Container>
        <s.SpacerSmall />
        <Container>
          <Text>
            But it seems that the bubble seal can’t hold the Atlantean’s power forever.
          </Text>
        </Container>
        <Container>
          <Text>
            as its power slowly weaken and our hero’s power getting stronger... They will soon come back to rebuild their home.
          </Text>
        </Container>
        </Container>
      </Container>
    </Container>
    )
  }
}
 
export default RoadmapHeader;

