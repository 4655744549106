import React, { Component } from "react";
// import './styles/animation.scss';
import './styles/animation2.css';

// function range(start, count) {
//     if(arguments.length == 1) {
//         count = start;
//         start = 0;
//     }

//     var foo = [];
//     for (var i = 0; i < count; i++) {
//         foo.push(start + i);
//     }
//     return foo;
// }

// class Bubble extends Component {
// 	render() {
// 		const elements = range(1, 100)
	  
// 		const items = []
	  
// 		for (const [index, value] of elements.entries()) {
// 		  items.push(<div class="circle-container"><div class="circle"></div></div>)
// 		}
	  
// 		return (
// 		  <div>
// 			{items}
// 		  </div>
// 		)
// 	  }
// }
 
// export default Bubble;


import { Container, Row, Col, Button } from 'react-bootstrap';

class Bubble extends Component {
	render() {
		return (
			<Col sm={12} lg={4} className="d-none d-lg-block">
				<div id="background-wrap">
					<div class="bubble x1"></div>
					<div class="bubble x2"></div>
					<div class="bubble x3"></div>
					<div class="bubble x4"></div>
					<div class="bubble x5"></div>
					<div class="bubble x6"></div>
					<div class="bubble x7"></div>
					<div class="bubble x8"></div>
					<div class="bubble x9"></div>
					<div class="bubble x10"></div>
				</div>
			</Col>
		)
	}
}

export default Bubble;