import React, { Component, useState } from "react";
import { Nav, Navbar, Container, NavDropdown , Button} from 'react-bootstrap'
import { Router, Route, NavLink, HashRouter, BrowserRouter } from "react-router-dom";
import styled from "styled-components";


import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";

import * as s from "./styles/globalStyles";
import ThemeContext from './Context'
import Brand from "./components/Brand";

const Text = styled.p`
  color: var(--primary-text);
  text-align: center;
  display: inline;
  line-height: 145%;
`

const StyledButton = styled.button`
  padding: 10px;
  border-radius: 6px;
  border: none;
  background: linear-gradient(180deg, #FFCF36 0%, #FCB034 88.02%);
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  width: 174px;
  margin-left 16px;
  margin-right 16px;
`

const StyledIcon = styled.img`
`

// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

// class CustomNavLink extends React.Component {
//     render() {
//         var isActive = this.context.router.route.location.pathname === this.props.to;
//         var className = isActive ? 'active' : '';

//         return(
//             <NavLink className={className} {...this.props}>
//                 {this.props.children}
//             </NavLink>
//         );
//     }
// }

// CustomNavLink.contextTypes = {
//     router: PropTypes.object
// };



function MainNavbar() {

	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);
	
	// const [claimingNft, setClaimingNft] = useState(false);
	// const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
	// const [mintAmount, setMintAmount] = useState(1);
	// const [CONFIG, SET_CONFIG] = useState({
	//   CONTRACT_ADDRESS: "",
	//   SCAN_LINK: "",
	//   NETWORK: {
	// 	NAME: "",
	// 	SYMBOL: "",
	// 	ID: 0,
	//   },
	//   NFT_NAME: "",
	//   SYMBOL: "",
	//   MAX_SUPPLY: 1,
	//   WEI_COST: 0,
	//   DISPLAY_COST: 0,
	//   GAS_LIMIT: 0,
	//   MARKETPLACE: "",
	//   MARKETPLACE_LINK: "",
	//   SHOW_BACKGROUND: false,
	// });


  
	// const getData = () => {
	// 	if (blockchain.account !== "" && blockchain.smartContract !== null) {
	// 	  dispatch(fetchData(blockchain.account));
	// 	}
	//   };

  // let state = {
  //   bg: "transparent"
  // };


  const truncateAddress = (input) => {
    let first = input.substring(0, 4)
    let last = input.split("").reverse().join("").substring(0, 4).split("").reverse().join("")
    let result = '0x' + first.toLowerCase() + "..." + last.toLowerCase()
    return result
  }

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const [bg, setNavbar] = useState("transparent")
  let listenScrollEvent = e => {
    // console.log("test", window.scrollY)
    if (window.scrollY > 50) {
      setNavbar("")
    } else {
      setNavbar("transparent")
    }
  }
  // document.body.addEventListener("scroll", listenScrollEvent);
  window.addEventListener("scroll", listenScrollEvent);

    return (
      <Navbar
        style={{ width: "100%", borderBottom: "2px solid #ffc836", backgroundColor: "#12385d"  }}
        className="justify-content-start"
        sticky="top"
        bg={bg}
        variant="dark"
        expand="lg"
      >
        <Container style={{ width: "100%" }}>
          <Nav>
            <Navbar.Brand href="#" style={{ padding: "0px", fontSize: "28px", color: "#ffffff", fontWeight: "bold" }}>
              <Brand/>
            </Navbar.Brand>
          </Nav>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-start" style={{ width: "100%", textAlign: "center" }}>
            <Nav defaultActiveKey="/">
              <Nav.Link href="#about" style={{ color: "#ffffff", margin: ".5rem 1rem", marginLeft: ".5rem", marginRight: ".5rem" }} >
                About
              </Nav.Link>
              <Nav.Link href="#roadmap" style={{ color: "#ffffff", margin: ".5rem 1rem", marginLeft: ".5rem", marginRight: ".5rem" }}>
                Roadmap
              </Nav.Link>
              <Nav.Link href="#shell" style={{ color: "#ffffff", margin: ".5rem 1rem", marginLeft: ".5rem", marginRight: ".5rem" }}>
                Shell
              </Nav.Link>
              <Nav.Link href="#team" style={{ color: "#ffffff", margin: ".5rem 1rem", marginLeft: ".5rem", marginRight: ".5rem" }}>
                Team
              </Nav.Link>
              {/* <Nav.Link href="#faq" style={{ color: "#ffffff", margin: ".5rem 1rem", marginLeft: ".5rem", marginRight: ".5rem" }}>
                FAQ
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end" style={{ textAlign: "center" }}>
            <Nav.Link target="_blank" rel="noopener noreferrer" href="https://discord.gg/eTVNCQ69">
              <StyledIcon alt={"discord"} src={"/config/images/discord.webp"} />
            </Nav.Link>
            <Nav.Link target="_blank" rel="noopener noreferrer" href="https://twitter.com/AtlanticaNFT">
              <StyledIcon alt={"twitter"} src={"/config/images/twitter.webp"} />
            </Nav.Link>
            {/* <Nav.Link>
              <StyledIcon alt={"opensea"} src={"/config/images/opensea.webp"} />
            </Nav.Link> */}
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <>
                <StyledButton
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}>
                  Connect Wallet
                </StyledButton>
              </>
            ):(
              <>
                <StyledButton style={{ fontSize: "16px", borderRadius: "6px", padding: "4px", paddingLeft: "14px", paddingRight: "14px", width: "100%" }} >
                  {truncateAddress(blockchain.account)}
                </StyledButton>
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
}
 
export default MainNavbar;