import React from 'react';

import styled from "styled-components";

const Text = styled.p`
  color: var(--primary-text);
  text-align: left;
  display: inline;
`;

/**
 * 
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>} props
 */
const Brand = (props) => {
  return (
    <span {...props}>
      <Text style={{ background: "linear-gradient(180deg, #FFCF36 0%, #FCB034 88.02%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", margin: "0px" }}>
        At
      </Text>
      <span>lantica</span>
    </span>
  );
}

export default Brand;
