import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";

const Text = styled.p`
  color: var(--primary-text);
  text-align: left;
  display: inline;
`;

const StyledImg = styled.img`
  border: 10px var(--primary);
  border-radius: 10%;
  width: 140px;
  transition: width 0.5s;
  z-index: 1;
  display: flex;
`;

const TeamItem = ({ title, subTitle, description, imgUrl }) => {
  return (
    <Row>
      <Col lg="4" style={{ display: "flex", justifyContent: "center"}}>
        <StyledImg alt={"Atlantica"} src={imgUrl}/>
      </Col>
      <Col lg="8" className="MobileCenterText" style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
        <Container style={{ fontSize: "18px", fontWeight: "bold", padding: "0px", color: "#7ef5e1" }}>
          {title}
        </Container>
        <Container style={{ padding: "0px", color: "var(--secondary-text)" }}>
          {subTitle}
        </Container>
        <Text style={{ marginBottom: "0px" }}>
          {description}
        </Text>
      </Col>
    </Row>
  );
};

export default TeamItem;
