import React from "react";
import { Container, Row, Col, Image } from 'react-bootstrap';
import Title from '../components/Title';
import Text from '../components/Text';
import styled from "styled-components";
import styles from './About.module.scss';
import Brand from "../components/Brand";

const About = () => {
  return (
    <Container
      style={{ 
        color: "var(--primary-text)",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "500"
      }}
    >
      <Row className="AboutPaddingDesktop">
        <Col className={styles.contentContainer}>
          <Row className={styles.header}>
            <Brand style={{ textTransform: 'uppercase' }}/>
          </Row>
          <Row style={{ paddingRight: "10%"}}>
            <Text style={{ paddingLeft: "0px" }} >
              Atlantica is a collection of 10,000 unique and randomly generated NFTs collection, completely illustrated with watercolor technique.
            </Text>
          </Row>
          <Row style={{ paddingRight: "10%"}}>
            <Text style={{ paddingLeft: "0px" }}>
              Atlantica world is full of mysteries, secrets and even undiscovered treasure! Owning a NFT will grant you access to all features and future events, stay tuned...
            </Text>
          </Row>
        </Col>
        <Col md={12} lg="auto" className={styles.imageContainer}>
          <Row>
            <Image src="/config/images/showcase.webp" />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
 
export default About;
