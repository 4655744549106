import React from 'react';
import { Container, Row } from 'react-bootstrap';
import styles from './Roadmap.module.scss';
import Title from '../components/Title';
import Text from '../components/Text';
import Phase from './components/Phase';

const roadmaps = [
  {
    title: 'PHASE 1: Return of the Atlantean',
    description: 'Release the bubble seal and save the Atlantean from their imprisonment by register for pre-whitelist and buying in public sale.',
    offset: 2,
  },
  {
    title: 'PHASE 2 : Rebuilding Atlantica',
    description: `After releasing Atlanteans from their cursed. It's time to stake them to rebuild for their kingdom.Every Atlantean you stake will be rewarded in $SHELL`,
    offset: 8,
  },
  {
    title: `PHASE 3 : Secret Shop Appear!`,
    description: `Amidst the battle, The mysterious merchant suddenly appears out of nowhere! then he says...“I sell my things by $SHELL. What’re you buying stranger?”`,
    offset: 1,
  },
  {
    title: `PHASE 4 : Underwater Colonies`,
    description: `The Underwater world is too large for one kingdom.To make peace across the seven seas, now every single Atlantean can build their own colony to help guarding their sea area.`,
    offset: 8,
  },
  {
    title: `PHASE 5 : Into the abyss`,
    description: `The territory of Atlantica is not yet cover all of underwater world and it seems like there’s something old and evil lurking inside the abyss. Waiting for the right moment to come out...`,
    offset: 0.5,
  },
]

const Roadmap = () => {
  return (
    <>
      <Container className={styles.container}>
        {/* <Row style={{ paddingTop: 64 }}>
          <Title>How it all began</Title>
        </Row>
        <Row>
          <Text>Beneath the sea... hiding among sea creatures stand the kingdom of Atlantica where the Atlantean live in harmony with duty to protect and preserve nature balance as it should be.</Text>
          <Text>Incautious by their extraordinary power, one day Atlantica was suddenly attacked by an unknown fearful force that make all of the Atlantean trapped inside the bubble seal for endless time and leave their glory kingdom into a ruin.</Text>
          <Text>But it seems that the bubble seal can’t hold the Atlantean’s power forever, as its power slowly weaken and our hero’s power getting stronger... They will soon come back to rebuild their home.</Text>
        </Row> */}
        {roadmaps.map(({ title, description, offset }) => (
          <Row className={styles.item}>
            <Phase title={title} description={description} offset={offset}/>
          </Row>
        ))}
      </Container>
      {/* <div
        style={{
          position: 'absolute',
          backgroundImage: 'url(/config/images/castle_bg.png)',
          backgroundSize: 'contain',
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
        }}
      /> */}
    </>
  );
};

export default Roadmap;
