import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";


// import { Nav, Navbar, NavDropdown , Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';

import {
  Route,
  NavLink,
  HashRouter,
  BrowserRouter
} from "react-router-dom";

import MainNavbar from "./Navbar";
import About from "./About/About";
import Roadmap from "./Roadmap";
import Team from "./Team";
import Codex from "./Codex";
import Mint from "./Mint";
import Shell from "./Shell";
import FAQ from "./FAQ";
import Bubble from "./Bubble";
import ThemeContext from "./Context";
import RoadmapHeader from "./RoadmapHeader";

import styles from './App.module.scss';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {

	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);


	// const state = {
	// 	dispatch: dispatch,
	// 	blockchain: blockchain,
	// 	data: data
	// }
	// const [ blockchain, setBlockchain ] = useState()
	// const [ data, setData ] = useState()

	const state = {
		blockchain: blockchain,
		data: data
	}
	// const MyContext = React.createContext(state)

  return (
	<ThemeContext.Provider value={state}>

    <s.Screen>
{/* <HashRouter> */}

      <s.Container
        flex={1}
        ai={"center"}
        style={{
          backgroundColor: "var(--secondary)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          zIndex: "0"
        }}
        flexDirection={"row"}
        image="/config/images/background.webp"
      >
      <MainNavbar/>

{/* <Bubble/> */}
		  
      
      {/* <ResponsiveWrapper flex={1} style={{ padding: 24 }}>					 */}
        <s.Container flex={1} ai={"center"}>
          <section id="about">
            <About/>
          </section>
          <section id="roadmap">
            <RoadmapHeader/>
          </section>
          <section
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              display: 'flex',
              // backgroundImage: 'url(/config/images/roadmap_bg.png)',
              // backgroundSize: 'cover',
              // backgroundPosition: 'bottom',
              // backgroundRepeat: 'no-repeat',
            }}
          >
            <div
              className={`${styles.containerWithBG} ${styles.bottom}`}
              style={{
                backgroundImage: 'url(/config/images/roadmap.webp)',
                zIndex: -1,
              }}
            />
            <Roadmap/>
          </section>
          {/* <section id="mint">
            <Mint/>
          </section> */}
          <section
            id="shell"
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              display: 'flex',
            }}
          >
            <div
              className={`${styles.containerWithBG} ${styles.top}`}
              style={{
                backgroundImage: 'url(/config/images/roadmap_flip.webp)',
                transform: 'scaleY(-1)',
                zIndex: -1
              }}
            />
            <Shell/>
          </section>
          <section
            id="team"
            className={styles.faqContainer}
            style={{
              backgroundImage: 'url(/config/images/seafloor.webp)',
            }}>
            <Team/>
          </section>
          {/* <section
            id="faq"
            className={styles.faqContainer}
            style={{
              backgroundImage: 'url(/config/images/seafloor.webp)',
            }}
          >
            <FAQ/>
          </section> */}
              {/* <Route exact path="/" component={About}/>
              <Route path="/about" component={About}/>
              <Route path="/roadmap" component={Roadmap}/>
              <Route path="/shell" component={Shell}/>
              <Route path="/FAQ" component={FAQ}/>
              <Route path="/team" component={Team}/>
              <Route path="/codex" component={Codex}/>
              <Route path="/mint" component={Mint}/> */}
          </s.Container>
      {/* </ResponsiveWrapper> */}

      
        {/* <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
        </s.Container> */}
      </s.Container>
    </s.Screen>
	</ThemeContext.Provider>
  );
}

export default App;
