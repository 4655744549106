import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const StyledImg = styled.img`
  border: 10px var(--primary);
  border-radius: 10%;
  width: 195px;
  transition: width 0.5s;
`;

const Header = styled.p`
  color: var(--primary-text);
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 1.6;
  display: inline;
`;


const Text = styled.p`
  color: var(--primary-text);
  text-align: center;
  display: inline;
`;

// Used for providing a wrapper around a component
export const StyledContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center center;
  background-repeat:   no-repeat;
`;



class FAQ extends Component {
  render() {
    return (
      <StyledContainer 
        flex={1}
        ai={"center"}
        style={{
          backgroundColor: "none",
          zIndex: "1",
          color: "var(--primary-text)", fontSize: "16px", textAlign: "center", fontWeight: "500", paddingTop: "100px"
        }}
        flexDirection={"row"}
      >
        <s.SpacerSmall />
        <Container>
          <Header>
            FAQs
          </Header>
        </Container>
        <Container style={{ paddingLeft: "5%", paddingRight: "5%" }} >
          <Row style={{ textAlign: "left", paddingTop: "16px" }}>
            <Container style={{ fontSize: "24px", fontWeight: "bold", color: "#7ef5e1" }}>
              พวกเหล่าน้องๆที่น่ารักพวกนี้คือใคร ?
            </Container>
            <s.SpacerSmall />
            <Container>
            เช่น น้องๆทั้ง 6666 คนถูกจับอยู่ใตทะเลในนคร Atlantica มาแสนนาน พวกคุณคือผู้ค้นพบนครเหล่านี้ มาช่วยน้องๆออกจากกรง แล้วฟื้นฟูนครแห่ง Atlantica กันเถอะ ว่าไปนั่น 555555
            </Container>
            <s.SpacerSmall />
          </Row>
          <Row style={{ textAlign: "left", paddingTop: "16px" }}>
            <Container style={{ fontSize: "24px", fontWeight: "bold", color: "#7ef5e1" }}>
              พวกเหล่าน้องๆที่น่ารักพวกนี้คือใคร ?
            </Container>
            <s.SpacerSmall />
            <Container>
            เช่น น้องๆทั้ง 6666 คนถูกจับอยู่ใตทะเลในนคร Atlantica มาแสนนาน พวกคุณคือผู้ค้นพบนครเหล่านี้ มาช่วยน้องๆออกจากกรง แล้วฟื้นฟูนครแห่ง Atlantica กันเถอะ ว่าไปนั่น 555555
            </Container>
            <s.SpacerSmall />
          </Row>
          <Row style={{ textAlign: "left", paddingTop: "16px" }}>
            <Container style={{ fontSize: "24px", fontWeight: "bold", color: "#7ef5e1" }}>
              พวกเหล่าน้องๆที่น่ารักพวกนี้คือใคร ?
            </Container>
            <s.SpacerSmall />
            <Container>
            เช่น น้องๆทั้ง 6666 คนถูกจับอยู่ใตทะเลในนคร Atlantica มาแสนนาน พวกคุณคือผู้ค้นพบนครเหล่านี้ มาช่วยน้องๆออกจากกรง แล้วฟื้นฟูนครแห่ง Atlantica กันเถอะ ว่าไปนั่น 555555
            </Container>
            <s.SpacerSmall />
          </Row>
          <Row style={{ textAlign: "left", paddingTop: "16px" }}>
            <Container style={{ fontSize: "24px", fontWeight: "bold", color: "#7ef5e1" }}>
              พวกเหล่าน้องๆที่น่ารักพวกนี้คือใคร ?
            </Container>
            <s.SpacerSmall />
            <Container>
            เช่น น้องๆทั้ง 6666 คนถูกจับอยู่ใตทะเลในนคร Atlantica มาแสนนาน พวกคุณคือผู้ค้นพบนครเหล่านี้ มาช่วยน้องๆออกจากกรง แล้วฟื้นฟูนครแห่ง Atlantica กันเถอะ ว่าไปนั่น 555555
            </Container>
            <s.SpacerSmall />
          </Row>
          <Row style={{ textAlign: "left", paddingTop: "16px" }}>
            <Container style={{ fontSize: "24px", fontWeight: "bold", color: "#7ef5e1" }}>
              พวกเหล่าน้องๆที่น่ารักพวกนี้คือใคร ?
            </Container>
            <s.SpacerSmall />
            <Container>
            เช่น น้องๆทั้ง 6666 คนถูกจับอยู่ใตทะเลในนคร Atlantica มาแสนนาน พวกคุณคือผู้ค้นพบนครเหล่านี้ มาช่วยน้องๆออกจากกรง แล้วฟื้นฟูนครแห่ง Atlantica กันเถอะ ว่าไปนั่น 555555
            </Container>
            <s.SpacerSmall />
          </Row>
          <Container style={{ paddingTop: "30%" }} />
        </Container>
      </StyledContainer>
    )
  }
}
 
export default FAQ;
