import React, { Component, useEffect, useState, useRef, useContext } from "react";

import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";


import * as s from "./styles/globalStyles";
import { Container, Row, Col, Button } from 'react-bootstrap';

import ThemeContext from './Context'
import styled from "styled-components";
import { useEffectOnce } from "react-use";


const Header = styled.p`
  color: var(--primary-text);
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 1.6;
  display: inline;
`;


function Mint() {
	// const state = useContext()
	// const dispatch = useDispatch();
	// const blockchain = useSelector((state) => state.blockchain);
	// const data = useSelector((state) => state.data);

	// const value = useContext(ThemeContext);

	// console.log(value)
	
	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);


	// const blockchain = value.blockchain
	// const data = value.data


	const [claimingNft, setClaimingNft] = useState(false);
	const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
	const [mintAmount, setMintAmount] = useState(1);
	const [CONFIG, SET_CONFIG] = useState({
	  CONTRACT_ADDRESS: "",
	  SCAN_LINK: "",
	  NETWORK: {
		NAME: "",
		SYMBOL: "",
		ID: 0,
	  },
	  NFT_NAME: "",
	  SYMBOL: "",
	  MAX_SUPPLY: 1,
	  WEI_COST: 0,
	  DISPLAY_COST: 0,
	  GAS_LIMIT: 0,
	  MARKETPLACE: "",
	  MARKETPLACE_LINK: "",
	  SHOW_BACKGROUND: false,
	});
  
	const claimNFTs = () => {
	  let cost = CONFIG.WEI_COST;
	  let gasLimit = CONFIG.GAS_LIMIT;
	  let totalCostWei = String(cost * mintAmount);
	  let totalGasLimit = String(gasLimit * mintAmount);
	  console.log("Cost: ", totalCostWei);
	  console.log("Gas limit: ", totalGasLimit);
	  setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
	  setClaimingNft(true);
	  blockchain.smartContract.methods
		.mint(mintAmount)
		.send({
		  gasLimit: String(totalGasLimit),
		  to: CONFIG.CONTRACT_ADDRESS,
		  from: blockchain.account,
		  value: totalCostWei,
		})
		.once("error", (err) => {
		  console.log(err);
		  setFeedback("Sorry, something went wrong please try again later.");
		  setClaimingNft(false);
		})
		.then((receipt) => {
		  console.log(receipt);
		  setFeedback(
			`WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
		  );
		  setClaimingNft(false);
		  dispatch(fetchData(blockchain.account));
		});
	};
  
	const decrementMintAmount = () => {
	  let newMintAmount = mintAmount - 1;
	  if (newMintAmount < 1) {
		newMintAmount = 1;
	  }
	  setMintAmount(newMintAmount);
	};
  
	const incrementMintAmount = () => {
	  let newMintAmount = mintAmount + 1;
	  if (newMintAmount > 10) {
		newMintAmount = 10;
	  }
	  setMintAmount(newMintAmount);
	};
  
	const getData = () => {
	  if (blockchain.account !== "" && blockchain.smartContract !== null) {
		dispatch(fetchData(blockchain.account));
	  }
	};
  
	const getConfig = async () => {
	  const configResponse = await fetch("/config/config.json", {
		headers: {
		  "Content-Type": "application/json",
		  Accept: "application/json",
		},
	  });
	  const config = await configResponse.json();
	  SET_CONFIG(config);
	};
  
	useEffectOnce(() => {
	  getConfig();
	});
  
	useEffect(() => {
	  getData();
	}, [blockchain.account]);

	
	return (
		<Container style= {{ textAlign: "center"}}>
        <Header>
          Coming Soon...
        </Header>
			{blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <Button size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT TO METAMASK
                    </Button>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <Button
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </Button>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <Button
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </Button>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <Button
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </Button>
                    </s.Container>
                  </>
                )}
		</Container>
	);
}

export default Mint;
  